console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
// document.addEventListener("touchstart", function(){}, true);






